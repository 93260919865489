
import {
  EBooleanSearch,
  EFilter,
  EProjectStatus,
  ERouter,
  ESimpleRegex,
  EVirtualProjectStatus
} from '@/helpers/enums'

const DETECT_STATUSES = [
  ...Object.values(EProjectStatus),
  ...Object.values(EVirtualProjectStatus)
].map((e) => `${e.toLowerCase().replace(/_/g, ' ')}`)

const getTalentFilters = [
  EFilter.NAME,
  EFilter.EXPERIENCE,
  EFilter.SKILL,
  EFilter.PHONE,
  EFilter.POOLS,
  EFilter.EMAIL,
  EFilter.RATE_RANGE,
  EFilter.TAG
]

const getProjectFilters = [
  EFilter.PROJECT_TITLE,
  EFilter.VMS_JOB_ID,
  EFilter.STATUS,
  EFilter.POOLS,
  EFilter.ASSIGNED_MSP_ID,
  EFilter.RATE_RANGE
]

export default {
  name: 'BaseSearchField',

  data() {
    return {
      searchQuery: null,
      suggestionsTalent: [],
      suggestionsProject: []
    }
  },

  watch: {
    $route() {
      this.$refs.globalSearch.blur()
      this.searchQuery = null
      this.suggestionsTalent = []
      this.suggestionsProject = []
    },

    searchQuery(val) {
      const trimmedValue = val?.trim()
      if (trimmedValue) {
        this.buildTalentSuggestions(trimmedValue)
        this.buildProjectSuggestions(trimmedValue)
      } else {
        this.suggestionsTalent = []
        this.suggestionsProject = []
      }
    }
  },

  methods: {
    buildTalentSuggestions(val) {
      const detectEmail = val.match(ESimpleRegex.EMAIL)
      const detectNumber = val.match(ESimpleRegex.NUMBER)
      const reusedTagFilter = {
        id: 'searchTalentTag',
        pathName: ERouter.SEARCH_TALENTS,
        filterItem: EFilter.TAG,
        searchQuery: encodeURIComponent(val)
      }

      if (detectNumber) {
        this.suggestionsTalent = [
          this.buildMenu({
            id: 'searchTalentRates',
            pathName: ERouter.SEARCH_TALENTS,
            filterItem: EFilter.RATE_RANGE,
            searchQuery: `0,${val}`
          }),
          this.buildMenu({
            id: 'searchTalentPools',
            pathName: ERouter.SEARCH_TALENTS,
            filterItem: EFilter.POOLS,
            searchQuery: val
          }),
          this.buildMenu({
            id: 'searchTalentPhone',
            pathName: 'search-talents',
            filterItem: EFilter.PHONE,
            searchQuery: `${EBooleanSearch.EQ}|${val}`
          }),
          this.buildMenu(reusedTagFilter)
        ]
      } else if (detectEmail) {
        this.suggestionsTalent = [
          this.buildMenu({
            id: 'searchTalentEmail',
            pathName: ERouter.SEARCH_TALENTS,
            filterItem: EFilter.EMAIL,
            searchQuery: `${EBooleanSearch.EQ}|${encodeURIComponent(val)}`
          }),
          this.buildMenu({
            id: 'searchTalentExperience',
            pathName: ERouter.SEARCH_TALENTS,
            filterItem: EFilter.EXPERIENCE,
            searchQuery: `${EBooleanSearch.LIKE}|${encodeURIComponent(val)}`
          })
        ]
      } else if (DETECT_STATUSES.includes(val)) {
        this.suggestionsTalent = []
      } else {
        this.suggestionsTalent = []

        for (const item of getTalentFilters) {
          if (![EFilter.TAG, EFilter.POOLS, EFilter.RATE_RANGE].includes(item)) {
            this.suggestionsTalent.push(
              this.buildMenu({
                id: `searchTalent_${item}`,
                pathName: ERouter.SEARCH_TALENTS,
                filterItem: item,
                searchQuery:
                  item === EFilter.NAME
                    ? encodeURIComponent(val)
                    : `${EBooleanSearch.LIKE}|${encodeURIComponent(val)}`
              })
            )
          }
        }

        this.suggestionsTalent.push(this.buildMenu(reusedTagFilter))
      }
    },

    buildProjectSuggestions(val) {
      const detectEmail = val.match(ESimpleRegex.EMAIL)
      const detectNumber = val.match(ESimpleRegex.NUMBER)

      if (detectNumber) {
        this.suggestionsProject = [
          this.buildMenu({
            id: 'searchProjectRate',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.RATE_RANGE,
            searchQuery: `0,${val}`
          }),
          this.buildMenu({
            id: 'searchProjectVmsJobId',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.VMS_JOB_ID,
            searchQuery: `${EBooleanSearch.LIKE}|${encodeURIComponent(val)}`
          }),
          this.buildMenu({
            id: 'searchProjectTitle',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.PROJECT_TITLE,
            searchQuery: encodeURIComponent(val)
          }),
          this.buildMenu({
            id: 'searchProjectPools',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.POOLS,
            searchQuery: val
          }),
          this.buildMenu({
            id: 'searchProjectMsp',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.ASSIGNED_MSP_ID,
            searchQuery: val
          })
        ]
      } else if (DETECT_STATUSES.includes(val)) {
        this.suggestionsProject = [
          this.buildMenu({
            id: 'searchProjectStatus',
            pathName: ERouter.SEARCH_PROJECTS,
            filterItem: EFilter.STATUS,
            searchQuery: val.toUpperCase()
          })
        ]
      } else if (detectEmail) {
        this.suggestionsProject = []
      } else {
        this.suggestionsProject = []

        for (const item of getProjectFilters) {
          const nonStandardFields = [
            EFilter.ASSIGNED_MSP_ID,
            EFilter.POOLS,
            EFilter.RATE_RANGE,
            EFilter.STATUS
          ]

          if (nonStandardFields.includes(item)) continue

          const searchQuery =
            item === EFilter.PROJECT_TITLE
              ? encodeURIComponent(val)
              : `${EBooleanSearch.LIKE}|${encodeURIComponent(val)}`

          this.suggestionsProject.push(
            this.buildMenu({
              id: `searchProject_${item}`,
              pathName: ERouter.SEARCH_PROJECTS,
              filterItem: item,
              searchQuery
            })
          )
        }
      }
    },

    buildMenu(prop) {
      return {
        id: prop.id,
        query: this.searchQuery,
        filter: prop.filterItem,
        queryPath: {
          name: prop.pathName,
          query: {
            [prop.filterItem]: prop.searchQuery
          }
        }
      }
    },

    showFilters(item) {
      const extenssion = item.filter.toLowerCase()
      const suggestionTranslation = this.$t(`msp.v2.search.suggestion.label.${extenssion}`)

      return this.$t('msp.v2.search.suggestion', {
        query: `<strong class="primary--text">${item.query}</strong>`,
        filter: `<strong class="primary--text">${suggestionTranslation}</strong>`
      })
    }
  }
}
