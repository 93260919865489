import { EFlowType, EPermission, EProjectStatus } from '@/helpers/enums'

export function projectMenuList({ ctx, disabled = [], projects = [] }) {
  const display = ctx.$store.state.search.projectMenu || []
  const idSuffix = ctx.source?.id ?? 'bulk'

  return [
    {
      id: `addToPool_${idSuffix}`,
      event: EFlowType.ADD_TO_POOL,
      icon: '$vuetify.icons.addPool',
      label: ctx.$t('msp.v2.link.add.to.pool'),
      disabled: disabled.includes(EFlowType.ADD_TO_POOL),
      display: display.includes(EFlowType.ADD_TO_POOL)
    },
    {
      id: `inviteTalent_${idSuffix}`,
      event: EFlowType.INVITE_TALENT,
      icon: '$vuetify.icons.addTalent',
      label: ctx.$t('msp.v2.link.invite.talent'),
      disabled:
        disabled.includes(EFlowType.INVITE_TALENT) ||
        projects.some((e) => e.status !== EProjectStatus.ACTIVE || e.filled || !e.freelancerVisible), // prettier-ignore
      display: display.includes(EFlowType.INVITE_TALENT)
    },
    {
      id: `allocateMsp_${idSuffix}`,
      event: EFlowType.ALLOCATE_MSP,
      icon: '$vuetify.icons.assignMsp',
      label: ctx.$t('msp.v2.link.allocate.msp'),
      disabled: disabled.includes(EFlowType.ALLOCATE_MSP),
      display:
        ctx.$useConfig(ctx.$EClientConfig.ENABLE_TEAM) && display.includes(EFlowType.ALLOCATE_MSP)
    },
    {
      id: `projectStatus_${idSuffix}`,
      event: EFlowType.CHANGE_PROJECT_STATUS,
      icon: '$vuetify.icons.changeStatus',
      label: ctx.$t('msp.v2.project.status.change'),
      disabled:
        disabled.includes(EFlowType.CHANGE_PROJECT_STATUS) ||
        projects.some((e) => !getAllowedStatusUpdates(ctx, e).length),
      display: display.includes(EFlowType.CHANGE_PROJECT_STATUS)
    },
    {
      id: `projectDelete_${idSuffix}`,
      event: EFlowType.PROJECT_DELETE,
      icon: '$vuetify.icons.delete',
      label: ctx.$t('msp.v2.link.delete'),
      disabled:
        disabled.includes(EFlowType.PROJECT_DELETE) || projects.some((e) => e.freelancerVisible),
      display:
        display.includes(EFlowType.PROJECT_DELETE) &&
        ctx.$checkPermission(EPermission.JOB_MANAGE_DELETE)
    }
  ]
}

export const projectManagementMenu = (ctx) => [
  {
    id: 'projectManagementLink',
    to: '#projectManagement',
    name: ctx.$t('msp.v2.project.job.posting'),
    display: true
  },
  {
    id: 'projectTimelineLink',
    to: '#projectTimeline',
    name: ctx.$t('msp.v2.project.manage.timeline.heading'),
    display: true
  },
  {
    id: 'projectPositionsLink',
    to: '#projectPositions',
    name: ctx.$t('msp.v2.project.manage.positions.heading'),
    display: ctx.positionsVisible
  },
  {
    id: 'projectPoolsLink',
    to: '#projectPools',
    name: ctx.$t('msp.v2.project.manage.talent.pools.publishing'),
    display: ctx.poolVisible
  },
  {
    id: 'projectTeamLink',
    to: '#projectTeam',
    name: ctx.$t('msp.v2.project.team'),
    display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_TEAM)
  },
  {
    id: 'projectQuestionsLink',
    to: '#projectQuestions',
    name: ctx.$t('msp.v2.project.questions'),
    display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_PAQ)
  }
]

/**
 * @see https://global-jira.randstadservices.com/browse/TWAGOS-9923
 * The documentation for the status updates is described on one of the attached images.
 * The part about vms integration is specified in the comment section.
 */
export function getAllowedStatusUpdates(ctx, { status, freelancerVisible, legalEntityName }) {
  if (!freelancerVisible) return []

  let isVmsIntegrated = true // to limit the potential actions if we cannot fetch the config

  if (legalEntityName) {
    const vmsConfig = ctx.$useLeConfig(legalEntityName, ctx.$ELeConfig.VMS_TYPE)
    const vmsType = vmsConfig?.vms_type ?? 'none'
    isVmsIntegrated = vmsType.toLowerCase() !== 'none'
  }

  if (isVmsIntegrated) {
    switch (status) {
      case EProjectStatus.ACTIVE:
        return [EProjectStatus.PAUSED]
      case EProjectStatus.PAUSED:
        return [EProjectStatus.ACTIVE]
    }
  } else {
    switch (status) {
      case EProjectStatus.ACTIVE:
        return [EProjectStatus.CLOSED, EProjectStatus.DELETED, EProjectStatus.PAUSED]
      case EProjectStatus.CLOSED:
        return [EProjectStatus.ACTIVE, EProjectStatus.DELETED]
      case EProjectStatus.PAUSED:
        return [EProjectStatus.ACTIVE, EProjectStatus.CLOSED]
    }
  }

  return []
}
