import { sortBy, compact } from 'lodash-es'

export const state = () => ({
  assigneesList: [],
  poolsList: [],
  tagsList: [],
  selectedList: [],
  projectsBucket: [],
  talentsBucket: [],
  talentMenu: [],
  projectMenu: [],
  getLocations: [],
  hiringManagersList: []
})

export const actions = {
  assigneesList({ commit }, payload) {
    const currentMspId = this.$auth.user.id
    const arr = payload.map((e) => ({ value: e.id, text: `${e.firstName} ${e.lastName}` }))
    const currentOnTop = sortBy(arr, ({ value }) => (value === currentMspId ? 0 : 1))

    commit('ASSIGNEES_LIST', currentOnTop)
  },

  poolsList({ commit }, payload) {
    const arr = payload.map((e) => ({
      value: e.id,
      text: this.$i18n.t(e.name),
      private: !e.ready4Public
    }))
    commit('POOLS_LIST', arr)
  },

  async tagsList({ commit, state }, forceReload = false) {
    if (state.tagsList.length && !forceReload) return

    const { data } = await this.$api.tag.getBulk({
      from: 0,
      size: 999
    })
    commit('TAGS_LIST', data?.results ?? [])
  },

  selectedList({ commit }, payload) {
    commit('SELECTED_LIST', payload)
  },

  async projectsBucket({ commit }, payload) {
    if (payload.length) {
      try {
        const idsList = payload.map((l) => `id = ${l}`).join(' OR ')
        const { data } = await this.$api.es.projectQuery({
          clientIds: [],
          filters: {},
          query: idsList,
          size: 100,
          offset: 0,
          sort: []
        })

        const projectArr = data.results

        commit('PROJECTS_BUCKET', projectArr)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  },

  async talentsBucket({ commit }, payload) {
    if (payload.length) {
      try {
        const idsList = payload.map((l) => `id = ${l}`).join(' OR ')
        const { data } = await this.$api.es.talentQuery({
          clientIds: [],
          filters: {},
          query: idsList,
          size: 100,
          offset: 0,
          sort: [{ name: 'id', dir: 'desc' }]
        })

        const talentArr = data.results.map((e) => ({
          id: e.id,
          fullName: `${e.firstName} ${e.lastName}`,
          firstName: e.firstName,
          lastName: e.lastName,
          emailVerified: e.emailVerified
        }))

        commit('TALENTS_BUCKET', talentArr)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  },

  talentMenu({ commit }, payload) {
    commit('TALENT_MENU', payload)
  },

  projectMenu({ commit }, payload) {
    commit('PROJECT_MENU', payload)
  },

  async getLocations({ commit }, payload) {
    try {
      const { data: locations } = await this.$api.utils.getLocations(payload)
      const treeList = []
      const countryList = [...new Set(locations.map(({ country }) => country))]

      for (let i = 0; i < countryList.length; i++) {
        const iso = countryList[i]

        treeList.push(
          {
            id: `country_${i}`,
            name: this.$parseCountryName(iso),
            value: iso,
            country: iso,
            isCountry: true
          },
          ...compact(
            locations.map((e, idx) => {
              if (e.country === iso) {
                return {
                  id: `city_${i}_${idx}`,
                  name: e.city,
                  value: e.city,
                  state: e.state,
                  country: iso,
                  isCountry: false
                }
              } else {
                return null
              }
            })
          )
        )
      }

      commit('GET_LOCATIONS', treeList)
    } catch ({ response }) {
      console.log(`API REQUEST FAILED FOR SITE LOCATIONS`)
    }
  },

  hiringManagersList({ commit }, payload) {
    const hiringManagers = payload.map(({ id, name }) => ({ text: name, value: id }))
    commit('HIRING_MANAGERS_LIST', hiringManagers)
  }
}

export const mutations = {
  ASSIGNEES_LIST: (state, payload) => {
    state.assigneesList = payload
  },
  POOLS_LIST: (state, payload) => {
    state.poolsList = payload
  },
  TAGS_LIST: (state, payload) => {
    state.tagsList = payload
  },
  SELECTED_LIST: (state, payload) => {
    state.selectedList = payload
  },
  PROJECTS_BUCKET: (state, payload) => {
    state.projectsBucket = payload
  },
  TALENTS_BUCKET: (state, payload) => {
    state.talentsBucket = payload
  },
  TALENT_MENU: (state, payload) => {
    state.talentMenu = payload
  },
  PROJECT_MENU: (state, payload) => {
    state.projectMenu = payload
  },
  GET_LOCATIONS: (state, payload) => {
    state.getLocations = payload
  },
  HIRING_MANAGERS_LIST: (state, payload) => {
    state.hiringManagersList = payload
  }
}

export const getters = {
  assigneesList: (state) => state.assigneesList,
  poolsList: (state) => state.poolsList,
  tagsList: (state) => state.tagsList,
  tagsListSimplified: (state) => state.tagsList.map(({ value }) => value),
  selectedList: (state) => state.selectedList,
  projectsBucket: (state) => state.projectsBucket,
  talentsBucket: (state) => state.talentsBucket,
  talentMenu: (state) => state.talentMenu,
  projectMenu: (state) => state.projectMenu,
  getLocations: (state) => state.getLocations,
  hiringManagersList: (state) => state.hiringManagersList
}
