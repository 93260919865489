import { EFilter, EPipelineStage, EJobApplicationStatus } from '@/helpers/enums'

const defaultPageSize = 10

export const statusQueryMap = async (options) => {
  const { context, status, labelId, id } = options

  const store = context.store || context.$store
  const { data: pipelineData } = await store.dispatch('project/getPipelineStages', id)

  const results = pipelineData.results
  const queryStatus = results.find((item) => item.name === status)

  if (!labelId) {
    return queryStatus.projectApplicationQuery
  } else {
    const queryLabel = queryStatus.subStages.find((subStage) => subStage.id === Number(labelId))
    return queryLabel.projectApplicationQuery
  }
}

export function statusToPipelineStage(stage) {
  switch (stage) {
    case EJobApplicationStatus.ACTIVE:
    case EJobApplicationStatus.UNCONFIRMED:
      return EPipelineStage.ACTIVE
    case EJobApplicationStatus.REJECTED_BY_MSP:
    case EJobApplicationStatus.WITHDRAWN:
      return EPipelineStage.REJECTED_WITHDRAW
    case EJobApplicationStatus.SENT_TO_CLIENT:
      return EPipelineStage.SEND2VMS
    case EJobApplicationStatus.HIRED:
      return EPipelineStage.HIRED
    case EJobApplicationStatus.REJECTED_BY_VMS:
      return EJobApplicationStatus.REJECTED
    default:
      return ''
  }
}

export const getApplicationStatusResults = async (obj) => {
  const { context, pageSize, query, params } = obj
  const getQueries = query || { page: 1 }
  const applyPageSize = pageSize || defaultPageSize
  const generatedOffset = (getQueries.page - 1) * applyPageSize || 0
  const buildQuery = []

  if (getQueries?.[EFilter.TALENT_NAME]) {
    // COMMENT: not using filter here due to this weird underscore in ES syntax
    buildQuery.push(`name ~ "${getQueries[EFilter.TALENT_NAME]}"`)
  } else if (getQueries?.[EFilter.NAME]) {
    buildQuery.push(`${EFilter.NAME} ~ "${getQueries[EFilter.NAME]}"`)
  }

  if (params.status === 'invited') {
    const { data } = await context.$api.es.invitationQuery(params.id, {
      query: buildQuery.join(' AND '),
      clientIds: [],
      filters: {},
      size: applyPageSize,
      offset: generatedOffset,
      sort: [{ name: 'id', dir: 'desc' }]
    })

    return {
      currentOffset: generatedOffset,
      results: data.results,
      total: data.totalResults
    }
  } else {
    const options = { labelId: params.labelId, status: params.status, id: params.id, context }
    const query = await statusQueryMap(options)
    buildQuery.push(query)

    const { data } = await obj.context.$api.es.applicationQueryDb({
      query: buildQuery.join(' AND '),
      clientIds: [],
      filters: [{ key: 'active_freelancers_only', value: true }],
      sort: [
        { name: 'msp_pipeline_last_updated', dir: 'asc' },
        { name: 'id', dir: 'asc' }
      ],

      size: applyPageSize,
      offset: generatedOffset
    })

    return {
      currentOffset: generatedOffset,
      results: data.results,
      total: data.totalResults
    }
  }
}
