import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=32572996&scoped=true&"
import script from "./empty.vue?vue&type=script&lang=js&"
export * from "./empty.vue?vue&type=script&lang=js&"
import style0 from "./empty.vue?vue&type=style&index=0&id=32572996&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32572996",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSystemTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/SystemTemplate.vue').default,BasePollingTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/PollingTemplate.vue').default,LayoutAnnouncementTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/AnnouncementTemplate.vue').default})
