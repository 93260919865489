import { del } from 'vue'
import { statusToPipelineStage } from '@/helpers/project-queries'

export const state = () => ({
  GDPRAllowed: false,
  canPublish: true,
  dataHarbourFilters: [],
  history: [],
  projectDetails: {},
  projectPools: [],
  statusDisplayList: [],
  statusMenu: {
    sourcing: [],
    internal: [],
    client: []
  },
  otherApplications: [],
  dedupePipelineStages: undefined
})

export const actions = {
  canPublish({ commit }, payload) {
    commit('CAN_PUBLISH', payload)
  },

  dataHarbourFilters({ commit }, payload) {
    commit('DATAHARBOUR_FILTERS', payload)
  },

  async refetchProject({ dispatch }, payload) {
    try {
      const { data } = await this.$api.project.get(payload)
      dispatch('projectDetails', data)
    } catch (err) {}
  },

  projectDetails({ commit }, payload) {
    const GDPRAllowed = this.$auth.user.legalEntityIds.includes(payload.legalEntityId)

    commit('GDPR_ALLOWED', GDPRAllowed)
    commit('PROJECT_DETAILS', payload)
  },

  projectPools({ commit }, payload) {
    commit('PROJECT_POOLS', payload)
  },

  statusDisplayList({ commit }, payload) {
    const loop = payload?.labels ? payload.labels : payload
    const makeList = []

    for (const item of loop) {
      if (item?.name) {
        makeList.push({
          id: item.id,
          name: item.name,
          internalName: item.name
        })
      }

      if (item?.subStages?.length) {
        makeList.push(
          ...item.subStages.map((e) => ({
            id: e.id,
            name: e.nameKey,
            internalName: e.name
          }))
        )
      } else {
        makeList.push({
          id: item.id,
          name: item.nameKey,
          internalName: item.name
        })
      }
    }

    if (payload?.labels && payload.status) {
      const status = statusToPipelineStage(payload.status)

      if (status) {
        const isStatusAlreadyPresent = makeList.find(
          (item) => item?.name.includes(status) || item?.internalName === status
        )

        if (!isStatusAlreadyPresent) {
          makeList.push({ id: null, internalName: status, name: `msp.v2.pipeline.text.${status}` })
        }
      }
    }

    commit('STATUS_DISPLAY_LIST', makeList)
  },

  statusMenu({ commit }, payload) {
    commit('STATUS_MENU', payload)
  },

  setHistory({ commit }, history) {
    commit('SET_HISTORY', history)
  },

  setOtherApplications({ commit }, applications) {
    commit('SET_OTHER_APPLICATIONS', applications)
  },

  getPipelineStages({ commit, state }, id) {
    if (state.dedupePipelineStages) return state.dedupePipelineStages

    const req = this.$api.project
      .getPipelineStages(id)
      .finally(() => commit('SET_DEDUPE_PIPELINE_STAGES', undefined))
    commit('SET_DEDUPE_PIPELINE_STAGES', req)

    return req
  }
}

export const mutations = {
  GDPR_ALLOWED: (state, payload) => {
    state.GDPRAllowed = payload
  },
  CAN_PUBLISH: (state, payload) => {
    state.canPublish = payload
  },
  DATAHARBOUR_FILTERS: (state, payload) => {
    state.dataHarbourFilters = payload
  },
  PROJECT_DETAILS: (state, payload) => {
    state.projectDetails = payload
  },
  PROJECT_POOLS: (state, payload) => {
    state.projectPools = payload
  },
  STATUS_DISPLAY_LIST: (state, payload) => {
    state.statusDisplayList = payload
  },
  STATUS_MENU: (state, payload) => {
    state.statusMenu = payload
  },
  SET_HISTORY: (state, history) => {
    state.history = history
  },
  SET_OTHER_APPLICATIONS: (state, applications) => {
    state.otherApplications = applications
  },
  DELETE_NOTE: (state, index) => {
    del(state.notes, index)
  },

  SET_DEDUPE_PIPELINE_STAGES: (state, payload) => {
    state.dedupePipelineStages = payload
  }
}

export const getters = {
  GDPRAllowed: ({ GDPRAllowed }) => GDPRAllowed,
  canPublish: ({ canPublish }) => canPublish,
  dataHarbourFilters: ({ dataHarbourFilters }) => dataHarbourFilters,
  projectDetails: ({ projectDetails }) => projectDetails,
  projectPools: ({ projectPools }) => projectPools,
  statusDisplayList: ({ statusDisplayList }) => statusDisplayList,
  statusMenu: ({ statusMenu }) => statusMenu,
  history: ({ history }) => history,
  otherApplications: ({ otherApplications }) => otherApplications
}
