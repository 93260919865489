export const EAssessmentStatus = Object.freeze({
  CREATED: 'cr',
  STARTED: 'st',
  CANCELED: 'cn',
  EXPIRED: 'ex',
  COMPLETED: 'cm'
})

export const ECalendarStatus = Object.freeze({
  CF: 'cf',
  NC: 'nc',
  CN: 'cn',
  AR: 'ar'
})

export const EFreelancerStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED',
  UNVERIFIED: 'UNVERIFIED'
})

export const EJobApplicationStatus = Object.freeze({
  ACTIVE: 'a',
  HIRED: 'h',
  REJECTED_BY_MSP: 'rm',
  REJECTED_BY_VMS: 'rv',
  SEND_FAILED: 'sf',
  SEND_PENDING: 'sp',
  SENT_TO_CLIENT: 's2c',
  UNCONFIRMED: 'uc',
  WITHDRAWN: 'w',
  PLACEHOLDER: 'placeholder'
})

export const ELiveInterviewStatus = Object.freeze({
  CANCELLED: 'can',
  CONFIRMED: 'cf',
  SENDING_FAILED: 'sf',
  UNCONFIRMED: 'uc'
})

export const EOnDemandInterviewStatus = Object.freeze({
  CANCELLED: 'can',
  COMPLETED: 'com',
  CREATED: 'cre',
  EXPIRED: 'exp',
  INVITED: 'inv',
  SENDING_FAILED: 'sf',
  STARTED: 'str'
})

export const EPoolMembershipStatus = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  ARCHIVED_ACCEPTED: 'ARCHIVED_ACCEPTED',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
})

export const EProjectForNewCandidatesStatus = Object.freeze({
  OPEN: 'OPEN',
  MANUALLY_CLOSED: 'MANUALLY_CLOSED',
  CLOSED_CANT_REOPEN: 'CLOSED_CANT_REOPEN',
  HALTED: 'HALTED'
})

export const EProjectStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED'
})

export const EVirtualProjectStatus = Object.freeze({
  FILLED: 'FILLED',
  PARTLY_FILLED: 'PARTLY_FILLED',
  UNPUBLISHED: 'UNPUBLISHED'
})

export const ELegacyProjectStatus = Object.freeze({
  UNPUBLISHED: 'UNPUBLISHED',
  OPEN: 'OPEN',
  PAUSED: 'PAUSED',
  PARTLY_FILLED: 'PARTLY_FILLED',
  FILLED: 'FILLED',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED',
  NOT_IN_POOL: 'NOT_IN_POOL',
  FINISHED: 'FINISHED'
})

export const EProjectStatusCountKeys = Object.freeze({
  ACTIVE: 'projectsOpen',
  PARTLY_FILLED: 'projectsPartlyFilled',
  FILLED: 'projectsFilled',
  CLOSED: 'projectsClosed',
  DELETED: 'projectsDeleted',
  UNPUBLISHED: 'projectsUnpublished',
  PAUSED: 'projectsPaused'
})

export const EApplicationInterviewStatus = Object.freeze({
  ACCEPTED: 'acc',
  CANCELED_BY_CLIENT: 'can',
  CREATED: 'cre',
  REJECTED_BY_TALENT: 'rej'
})

export const EApplicationOfferStatus = Object.freeze({
  ACCEPTED: 'acc',
  CANCELED_BY_CLIENT: 'can',
  CREATED: 'cre',
  REJECTED_BY_TALENT: 'rej'
})

export const EPollingStatus = Object.freeze({
  CREATED: 'CREATED',
  RESUMED: 'RESUMED',
  SCHEDULED: 'SCHEDULED',
  RUNNING: 'RUNNING',
  SERVER_STOPPED: 'SERVER_STOPPED',
  USER_STOPPED: 'USER_STOPPED',
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL'
})
