import {
  ELiveInterviewStatus,
  EOnDemandInterviewStatus,
  EPipelineStage,
  EProjectStatus,
  EProjectStatusCountKeys,
  EVirtualProjectStatus,
  ELegacyProjectStatus
} from '@/helpers/enums'

export function statusList(ctx, activeStatus) {
  return [
    makeStatusBadge(ctx, { status: EProjectStatus.ACTIVE }, activeStatus),
    makeStatusBadge(ctx, { status: EProjectStatus.PAUSED }, activeStatus),
    makeStatusBadge(ctx, { status: EProjectStatus.ACTIVE, partiallyFilled: true }, activeStatus),
    makeStatusBadge(ctx, { status: EProjectStatus.CLOSED, filled: true }, activeStatus),
    makeStatusBadge(ctx, { status: EProjectStatus.ACTIVE, freelancerVisible: false }, activeStatus), // Unpublished
    makeStatusBadge(ctx, { status: EProjectStatus.CLOSED }, activeStatus),
    makeStatusBadge(ctx, { status: EProjectStatus.DELETED }, activeStatus)
  ]
}

export function makeStatusBadge(
  ctx,
  { status, filled = false, freelancerVisible, partiallyFilled = false } = {},
  activeStatus
) {
  switch (status) {
    case EProjectStatus.ACTIVE:
      if (freelancerVisible === false) {
        return {
          value: EVirtualProjectStatus.UNPUBLISHED,
          countKey: EProjectStatusCountKeys.UNPUBLISHED,
          text: ctx.$t('msp.v2.status.chip.unpublished'),
          subtitle: ctx.$t('msp.v2.status.chip.unpublished.subtitle'),
          color: 'grey lighten-1',
          icon: '$vuetify.icons.delimiter',
          active: activeStatus === EVirtualProjectStatus.UNPUBLISHED
        }
      }

      if (partiallyFilled) {
        return {
          value: EVirtualProjectStatus.PARTLY_FILLED,
          countKey: EProjectStatusCountKeys.PARTLY_FILLED,
          text: ctx.$t('msp.v2.status.chip.partially.filled'),
          subtitle: ctx.$t('msp.v2.status.chip.partially.filled.subtitle'),
          color: 'blue',
          icon: '$vuetify.icons.delimiter',
          active: activeStatus === EVirtualProjectStatus.PARTLY_FILLED
        }
      }

      return {
        value: EProjectStatus.ACTIVE,
        countKey: EProjectStatusCountKeys.ACTIVE,
        text: ctx.$t('msp.v2.status.chip.active'),
        subtitle: ctx.$t('msp.v2.status.chip.active.subtitle'),
        color: 'green',
        icon: '$vuetify.icons.delimiter',
        active: activeStatus === EProjectStatus.ACTIVE
      }
    case EProjectStatus.CLOSED:
      if (filled) {
        return {
          value: EVirtualProjectStatus.FILLED,
          countKey: EProjectStatusCountKeys.FILLED,
          text: ctx.$t('msp.v2.status.chip.filled'),
          subtitle: ctx.$t('msp.v2.status.chip.filled.subtitle'),
          color: 'grey lighten-1',
          icon: '$vuetify.icons.success',
          active: activeStatus === EVirtualProjectStatus.FILLED
        }
      }

      return {
        value: EProjectStatus.CLOSED,
        countKey: EProjectStatusCountKeys.CLOSED,
        text: ctx.$t('msp.v2.status.chip.closed'),
        subtitle: ctx.$t('msp.v2.status.chip.closed.subtitle'),
        color: 'grey lighten-1',
        icon: '$vuetify.icons.closeFilled',
        active: activeStatus === EProjectStatus.CLOSED
      }

    case EProjectStatus.DELETED:
      return {
        value: EProjectStatus.DELETED,
        countKey: EProjectStatusCountKeys.DELETED,
        text: ctx.$t('msp.v2.status.chip.deleted'),
        subtitle: ctx.$t('msp.v2.status.chip.deleted.subtitle'),
        color: 'error',
        icon: '$vuetify.icons.close',
        active: activeStatus === EProjectStatus.DELETED
      }
    case EProjectStatus.PAUSED:
      return {
        value: EProjectStatus.PAUSED,
        countKey: EProjectStatusCountKeys.PAUSED,
        text: ctx.$t('msp.v2.status.chip.paused'),
        subtitle: ctx.$t('msp.v2.status.chip.paused.subtitle'),
        color: 'red',
        icon: '$vuetify.icons.pausedCircle',
        active: activeStatus === EProjectStatus.PAUSED
      }
    // *********************
    //    LEGACY STATUSES
    // (for history entries)
    // *********************
    case ELegacyProjectStatus.OPEN:
      return {
        value: ELegacyProjectStatus.OPEN,
        text: ctx.$t('msp.v2.status.chip.open'),
        color: 'green',
        icon: '$vuetify.icons.delimiter'
      }
    case ELegacyProjectStatus.PARTLY_FILLED:
      return {
        value: ELegacyProjectStatus.PARTLY_FILLED,
        text: ctx.$t('msp.v2.status.chip.partially.filled'),
        color: 'blue',
        icon: '$vuetify.icons.delimiter'
      }
    case ELegacyProjectStatus.FILLED:
      return {
        value: ELegacyProjectStatus.FILLED,
        text: ctx.$t('msp.v2.status.chip.filled'),
        color: 'grey lighten-1',
        icon: '$vuetify.icons.success'
      }
    case ELegacyProjectStatus.UNPUBLISHED:
      return {
        value: ELegacyProjectStatus.UNPUBLISHED,
        text: ctx.$t('msp.v2.status.chip.unpublished'),
        color: 'grey lighten-1',
        icon: '$vuetify.icons.delimiter'
      }
    case ELegacyProjectStatus.NOT_IN_POOL:
      return {
        value: ELegacyProjectStatus.NOT_IN_POOL,
        text: ctx.$t('msp.v2.status.chip.not.in.pool'),
        color: 'grey lighten-1',
        icon: '$vuetify.icons.delimiter'
      }
    case ELegacyProjectStatus.FINISHED:
      return {
        value: ELegacyProjectStatus.FINISHED,
        text: ctx.$t('msp.v2.status.chip.finished'),
        color: 'black',
        icon: '$vuetify.icons.delimiter'
      }
  }
}

export const statsChipCloud = (ctx, id) => [
  {
    name: 'ucappcount',
    count: ctx.source.ucAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.ACTIVE)
  },
  {
    name: 'aappcount',
    count: ctx.source.aAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.ACTIVE)
  },
  {
    name: 'rmappcount',
    count: ctx.source.rmAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.REJECTED_WITHDRAW)
  },
  {
    name: 'rvappcount',
    count: ctx.source.rvAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.REJECTED)
  },
  {
    name: 's2cappcount',
    count: ctx.source.s2cAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.SEND2VMS)
  },
  {
    name: 'sfappcount',
    count: ctx.source.sfAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.SEND2VMS)
  },
  {
    name: 'happcount',
    count: ctx.source.hAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.HIRED)
  },
  {
    name: 'wappcount',
    count: ctx.source.wAppCount,
    to: ctx.$link.projectStatus(id, EPipelineStage.REJECTED_WITHDRAW)
  }
]

export const onDemandInterviewStatusList = (ctx) => {
  const statusList = Object.values(EOnDemandInterviewStatus)
  const returnList = []

  const objByStatus = (status) => {
    switch (status) {
      case EOnDemandInterviewStatus.COMPLETED:
        return {
          icon: '$vuetify.icons.video',
          color: 'primary'
        }
      case EOnDemandInterviewStatus.STARTED:
        return {
          icon: '$vuetify.icons.video',
          color: 'primary'
        }
      case EOnDemandInterviewStatus.SENDING_FAILED:
        return {
          icon: '$vuetify.icons.warning',
          color: 'warning'
        }
      case EOnDemandInterviewStatus.EXPIRED:
        return {
          icon: '$vuetify.icons.videoOff',
          color: 'primary'
        }
      case EOnDemandInterviewStatus.CANCELLED:
        return {
          icon: '$vuetify.icons.close',
          color: 'error'
        }
      case EOnDemandInterviewStatus.INVITED:
        return {
          icon: '$vuetify.icons.video',
          color: 'grey darken-1'
        }
      case EOnDemandInterviewStatus.CREATED:
        return {
          icon: '$vuetify.icons.video',
          color: 'grey darken-1'
        }
    }
  }

  for (const item of statusList) {
    returnList.push({
      ...objByStatus(item),
      text: ctx.$t(`msp.v2.ondemand.interview.label.${item}`),
      status: item
    })
  }

  return returnList
}

export const liveInterviewStatusList = (ctx) => {
  const statusList = Object.values(ELiveInterviewStatus)
  const returnList = []

  const objByStatus = (status) => {
    switch (status) {
      case ELiveInterviewStatus.CONFIRMED:
        return {
          icon: '$vuetify.icons.calendar',
          color: 'primary'
        }
      case ELiveInterviewStatus.SENDING_FAILED:
        return {
          icon: '$vuetify.icons.warning',
          color: 'warning'
        }
      case ELiveInterviewStatus.CANCELLED:
        return {
          icon: '$vuetify.icons.close',
          color: 'error'
        }
      case ELiveInterviewStatus.UNCONFIRMED:
        return {
          icon: '$vuetify.icons.calendar',
          color: 'grey darken-1'
        }
    }
  }

  for (const item of statusList) {
    returnList.push({
      ...objByStatus(item),
      text: ctx.$t(`msp.v2.live.interview.label.${item}`),
      status: item
    })
  }

  return returnList
}
