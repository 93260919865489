import { forwardModel } from '@twago/configs'
import { makeErrorHandler, makeSuccessHandler } from '@twago/axios-intercept'

export default ({ redirect, app, $axios, req, store, $config, isDev, error }) => {
  const xFwd = forwardModel({ req, $config, isDev })

  if (xFwd) {
    $axios.defaults.headers.common['x-forwarded-host'] = xFwd
  }

  const { name: clientName } = store.state.clientContext
  const defaultErrorMsg = app.$i18n.t('msp.v2.message.generic.error')

  const handleSuccess = makeSuccessHandler({ clientName })
  const handleError = makeErrorHandler({
    clientName,
    redirectToLogin,
    showErrorPage: showErrorToast,
    showErrorToast
  })

  function redirectToLogin() {
    redirect(app.$link.login())
  }

  function showErrorToast(data, config = {}) {
    const toastConf = { type: 'error', ...config }
    const vendorErrorMsg = data?.error
    const beErrorMessages = data?.messages || []
    let toastMsg = defaultErrorMsg

    if (vendorErrorMsg) {
      toastMsg = app.$i18n.t(vendorErrorMsg)
    } else if (Array.isArray(beErrorMessages) && beErrorMessages.length) {
      toastMsg = beErrorMessages.map((message) => app.$i18n.t(message))
    }

    app.$toast.fire(toastMsg, toastConf)
  }

  $axios.interceptors.response.use(handleSuccess, handleError)
}
