import { ECurrency, EQuestionType } from '@/helpers/enums'

export const currencySign = (currency, verb) => {
  switch (currency) {
    case ECurrency.EUR:
      return `€ ${verb}`
    case ECurrency.USD:
      return `$ ${verb}`
    case ECurrency.CHF:
      return `CHF ${verb}`
    case ECurrency.GBP:
      return `£ ${verb}`
    case ECurrency.CNY:
      return `元 ${verb}`
    case ECurrency.CAD:
      return `$ ${verb}`
    case ECurrency.INR:
      return `₹ ${verb}`
    case ECurrency.HKD:
      return `HK$ ${verb}`
    case ECurrency.SGD:
      return `S$ ${verb}`
    case ECurrency.SEK:
      return `kr ${verb}`
    case ECurrency.MXN:
      return `$ ${verb}`
    case ECurrency.CRC:
      return `₡ ${verb}`
    case ECurrency.BRL:
      return `R$ ${verb}`
    case ECurrency.AUD:
      return `$ ${verb}`
    case ECurrency.JPY:
      return `¥ ${verb}`
    case ECurrency.MYR:
      return `MR ${verb}`
    case ECurrency.NZD:
      return `$ ${verb}`
    default:
      return `€ ${verb}`
  }
}

export const arraySort = (arr, key, order) => {
  arr.sort((a, b) => {
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0

    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  })

  return arr
}

export const arrayFlatten = (arr) => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(Array.isArray(toFlatten) ? arrayFlatten(toFlatten) : toFlatten)
  }, [])
}

export const questionTypeFilter = (results) => {
  const remap = results.map((e) => ({ ...e, hide: e.hide || false }))

  const defaultQuestions = remap
    .filter((q) => q.questionType === EQuestionType.DEFAULT)
    .sort((a, b) => a.position - b.position)
  const specificQuestions = remap
    .filter((q) => q.questionType === EQuestionType.JOB_SPECIFIC)
    .sort((a, b) => a.position - b.position)

  return [[...defaultQuestions], [...specificQuestions]]
}

export const connectCalendar = (clientId) => {
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('action', oauth2Endpoint)

  const params = {
    client_id: clientId,
    redirect_uri: `${location.origin}/calendar/callback`,
    access_type: 'offline',
    prompt: 'consent',
    response_type: 'code',
    include_granted_scopes: 'true',
    state: 'state_parameter_passthrough_value',
    scope: 'https://www.googleapis.com/auth/calendar'
  }

  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}
export function getJobFieldSettings(ctx, store, legalEntityId) {
  const legalEntities = store.state.clientContext.localLegalEntities
  const legalEntity = legalEntities.find(({ id }) => id === legalEntityId)
  const legalEntityName = legalEntity?.name
  if (legalEntityName) {
    const rawConfig = ctx.$useLeConfig(legalEntityName, ctx.$ELeConfig.JOB_FIELD_SETTINGS)
    return (
      rawConfig.recruiter ?? {
        assignmentPeriodStart: undefined,
        assignmentPeriodEnd: undefined,
        hiringDeadline: undefined,
        maxSubmissions: undefined,
        positionsNumber: undefined,
        publishDateVisible: undefined,
        workModelType: undefined
      }
    )
  }
}

export function createBlobUrl(response) {
  const contentType = response.headers['content-type']
  const blob = new Blob([response.data], { type: contentType })
  const url = URL.createObjectURL(blob)
  return url
}
