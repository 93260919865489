export const ETalentHistoryEntryType = Object.freeze({
  LOG: 0,
  POOL: 1,
  PROJECT: 2
})

export const EApplicationHistoryOperation = Object.freeze({
  CREATED: 'CREATED',
  EMAIL_TRIGGERED: 'EMAIL_TRIGGERED',
  MSP_ADD_LABEL: 'MSP_ADD_LABEL',
  MSP_CHANGED_STATUS: 'MSP_CHANGED_STATUS',
  MSP_EDIT_ANSWER: 'MSP_EDIT_ANSWER',
  MSP_LOG_CALL: 'MSP_LOG_CALL',
  MSP_LOG_INTERVIEW: 'MSP_LOG_INTERVIEW',
  MSP_LOG_NOTE: 'MSP_LOG_NOTE',
  MSP_REMOVE_LABEL: 'MSP_REMOVE_LABEL',
  MSP_UPDATED: 'MSP_UPDATED',
  RULES_ACCEPTED: 'RULES_ACCEPTED',
  SYSTEM_ADD_LABEL: 'SYSTEM_ADD_LABEL',
  SYSTEM_CHANGED_STATUS: 'SYSTEM_CHANGED_STATUS',
  SYSTEM_REMOVE_LABEL: 'SYSTEM_REMOVE_LABEL',
  TOS_ACCEPTED: 'TOS_ACCEPTED',
  UPDATED: 'UPDATED',
  VMS_CHANGED_STATUS: 'VMS_CHANGED_STATUS',
  VMS_INTERVIEW_CREATED: 'VMS_INTERVIEW_CREATED',
  VMS_INTERVIEW_UPDATED: 'VMS_INTERVIEW_UPDATED',
  VMS_OFFER_CREATED: 'VMS_OFFER_CREATED',
  VMS_OFFER_UPDATED: 'VMS_OFFER_UPDATED',
  WITHDRAWN: 'WITHDRAWN'
})

export const EProjectHistoryOperation = Object.freeze({
  ADMIN_CHANGES_POOL: 'ADMIN_CHANGES_POOL',
  ADMIN_JOB_CHANGES: 'ADMIN_JOB_CHANGES',
  ASSIGNEE_UPDATED: 'JOB_ASSIGNEE_UPDATED',
  ASSIGNMENT_END_EDITED: 'ASSIGNMENT_END_EDITED',
  ASSIGNMENT_START_EDITED: 'ASSIGNMENT_START_EDITED',
  ATTACHMENTS_UPDATED: 'JOB_ATTACHMENTS_UPDATED',
  CREATED: 'JOB_CREATED',
  HIRING_DEADLINE_EDITED: 'HIRING_DEADLINE_EDITED',
  MAX_SUBMISSIONS_EDITED: 'MAX_SUBMISSIONS_EDITED',
  MAX_SUBMISSIONS_OVERRIDE_EDITED: 'MAX_SUBMISSIONS_OVERRIDE_EDITED',
  MULTIPLE_APPLICATIONS_ALLOWED_UPDATED: 'MULTIPLE_APPLICATIONS_ALLOWED_UPDATED',
  PAUSED: 'JOB_PAUSED',
  CLOSED: 'JOB_CLOSED',
  POOL_ADDED: 'JOB_ADD_POOL',
  POOL_REMOVED: 'JOB_REMOVE_POOL',
  POSITIONS_NUMBER_EDITED: 'POSITIONS_NUMBER_EDITED',
  POSITIONS_NUMBER_OVERRIDE_EDITED: 'POSITIONS_NUMBER_OVERRIDE_EDITED',
  PUBLIC_DESCRIPTION_UPDATED: 'PUBLIC_DESCRIPTION_UPDATED',
  PUBLISHED: 'JOB_TALENT_VISIBLE',
  PUBLISHED_OBSOLETE: 'JOB_PUBLISHED',
  RATE_UPDATED: 'RATE_UPDATED',
  RATE_UPDATED_MAX: 'RATE_UPDATED_MAX',
  RATE_UPDATED_MIN: 'RATE_UPDATED_MIN',
  REASON_FOR_CLOSING_UPDATED: 'REASON_FOR_CLOSING_UPDATED',
  REMOTE_FLAG_EDITED: 'REMOTE_FLAG_EDITED',
  SITE_ADDRESS_EDITED: 'SITE_ADDRESS_EDITED',
  SITE_ADDRESS_TWO_EDITED: 'SITE_ADDRESS_TWO_EDITED',
  SITE_CITY_EDITED: 'SITE_CITY_EDITED',
  SITE_CODE_EDITED: 'SITE_CODE_EDITED',
  SITE_COUNTRY_EDITED: 'SITE_COUNTRY_EDITED',
  SITE_STATE_EDITED: 'SITE_STATE_EDITED',
  SITE_POSTAL_CODE_EDITED: 'SITE_POSTAL_CODE_EDITED',
  STATUS_UPDATED: 'JOB_STATUS_UPDATED',
  TITLE_EDITED: 'TITLE_EDITED',
  UNPAUSED: 'JOB_UNPAUSED'
})

export const ETalentHistoryOperation = Object.freeze({
  ADMIN_CHANGES_POOL: 'ADMIN_CHANGES_POOL',
  FREELANCER_ACTIVATED: 'FREELANCER_ACTIVATED',
  FREELANCER_APPLIES_POOL: 'FREELANCER_APPLIES_POOL',
  FREELANCER_REGISTERED: 'FREELANCER_REGISTERED',
  FREELANCER_UPDATED_EMAIL: 'FREELANCER_UPDATED_EMAIL',
  FREELANCER_WITHDRAWS_POOL: 'FREELANCER_WITHDRAWS_POOL',
  MSP_ACCEPTS_POOL: 'MSP_ACCEPTS_POOL',
  MSP_ACTIVATES: 'MSP_ACTIVATES',
  MSP_ADDED_ITEM_CV: 'MSP_ADDED_ITEM_CV',
  MSP_DEACTIVATES: 'MSP_DEACTIVATES',
  MSP_DELETED_ITEM_CV: 'MSP_DELETED_ITEM_CV',
  MSP_DELETED_POOL: 'MSP_DELETED_POOL',
  MSP_DELETES: 'MSP_DELETES',
  MSP_EDITED_ITEM_CV: 'MSP_EDITED_ITEM_CV',
  MSP_EMAIL: 'MSP_EMAIL',
  MSP_MOVES_INTO_POOL: 'MSP_MOVES_INTO_POOL',
  MSP_INVITES_TALENT_TO_JOB: 'MSP_INVITES_TALENT_TO_JOB',
  MSP_REJECTS_POOL: 'MSP_REJECTS_POOL',
  PP_ACCEPTED: 'PP_ACCEPTED',
  TOS_ACCEPTED: 'TOS_ACCEPTED',
  UPDATED_PP_ACCEPTED: 'UPDATED_PP_ACCEPTED',
  UPDATED_TOS_ACCEPTED: 'UPDATED_TOS_ACCEPTED'
})

export const EPossibleHistoryEntryAuthor = Object.freeze({
  ADMIN: 'admin',
  MSP: 'msp',
  TALENT: 'freelancer',
  VMS: 'vms'
})
