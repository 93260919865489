import { EPipelineStage } from '@/helpers/enums'

export const toolbarMenu = (ctx) => [
  {
    id: 'dashboard',
    link: ctx.$link.dashboard(),
    icon: '$vuetify.icons.home',
    text: ctx.$t('msp.v2.menu.dashboard'),
    display: true
  },
  {
    id: 'analytics',
    link: ctx.$link.analytics(),
    icon: '$vuetify.icons.analytics',
    text: ctx.$t('msp.v2.menu.analytics'),
    display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_ANALYTICS_PAGE)
  },
  {
    id: 'projects',
    link: ctx.$link.searchProjects(),
    icon: '$vuetify.icons.work',
    text: ctx.$t('msp.v2.menu.projects'),
    display: true
  },
  {
    id: 'talents',
    link: ctx.$link.searchTalents(),
    icon: '$vuetify.icons.talent',
    text: ctx.$t('msp.v2.menu.talents'),
    display: true
  },
  {
    id: 'talentPools',
    link: ctx.$link.pools(),
    icon: '$vuetify.icons.pools',
    text: ctx.$t('msp.v2.menu.pools'),
    display: true
  },
  {
    id: 'settings',
    link: ctx.$link.settings(),
    icon: '$vuetify.icons.settings',
    text: ctx.$t('msp.v2.menu.settings'),
    display: true
  }
]

export const pipelinesMenu = (obj) => {
  const pipeline = {
    sourcing: [],
    internal: [],
    client: []
  }

  const sourcingTypes = [
    EPipelineStage.DATA_HARBOUR,
    EPipelineStage.SUGGESTED,
    EPipelineStage.INVITED
  ]

  const sourcingOrder = [
    EPipelineStage.SUGGESTED,
    EPipelineStage.DATA_HARBOUR,
    EPipelineStage.INVITED
  ]

  const internalTypes = [EPipelineStage.ACTIVE, EPipelineStage.REJECTED_WITHDRAW]
  const clientTypes = [EPipelineStage.SEND2VMS, EPipelineStage.HIRED, EPipelineStage.REJECTED]
  let externalTotalCount = 0

  const labelsArr = (arr, parentName) => {
    const labelsArr = []
    for (const item of arr) {
      labelsArr.push({
        id: item.id,
        title: obj.ctx.$i18n.t(item.nameKey),
        link: obj.ctx.$link.projectLabel(obj.params.id, parentName, item.id),
        count:
          item.entriesCount === 0 ? item.entriesCount : obj.ctx.$compactNumber(item.entriesCount),
        label: true
      })
    }

    return labelsArr
  }

  const statusObj = (key) => {
    const getCountForDataHarbour = (key, obj) => {
      if (key.dhCounts?.spotted > 0) {
        return `${obj.ctx.$t('msp.v2.talent.data.harbour.new.count', {
          dhCount: obj.ctx.$compactNumber(key.dhCounts.spotted)
        })}`
      }

      // Sum all dhCounts values that are above 0 and not spotted
      const totalDhCounts = Object.entries(key.dhCounts || {}).reduce((sum, [key, value]) => {
        return key !== 'spotted' && value > 0 ? sum + value : sum
      }, 0)

      // If no values are above 0, set count to entriesInStageCount
      return totalDhCounts > 0
        ? obj.ctx.$compactNumber(totalDhCounts)
        : obj.ctx.$compactNumber(key.entriesInStageCount)
    }

    return [
      {
        id: key.name,
        title: obj.ctx.$i18n.t(`msp.v2.pipeline.text.${key.name.toLowerCase()}`),
        link: obj.ctx.$link.projectStatus(obj.params.id, key.name),
        isBorderDashed: key.name === EPipelineStage.INVITED,
        count:
          key.name === EPipelineStage.SEND2VMS && obj.maxSubmissions === -1
            ? `${obj.ctx.$compactNumber(key.entriesInStageAndSubStagesCount)}`
            : key.name === EPipelineStage.DATA_HARBOUR
            ? getCountForDataHarbour(key, obj)
            : obj.ctx.$compactNumber(key.entriesInStageCount),
        isNewCount: !!(key.name === EPipelineStage.DATA_HARBOUR && key.dhCounts?.spotted > 0),
        icon: {
          iconName:
            key.name === EPipelineStage.SUGGESTED
              ? '$vuetify.icons.robot'
              : key.name === EPipelineStage.DATA_HARBOUR
              ? '$vuetify.icons.creation'
              : false,
          iconSize:
            key.name === EPipelineStage.SUGGESTED
              ? '16'
              : key.name === EPipelineStage.DATA_HARBOUR
              ? '18'
              : false
        }
      },
      ...labelsArr(key.subStages, key.name)
    ]
  }

  for (const key of obj.data) {
    if (key.name === EPipelineStage.DATA_HARBOUR && key.entriesInStageCount === -1) continue

    if (sourcingTypes.includes(key.name)) {
      pipeline.sourcing.push(...statusObj(key))
    }

    if (internalTypes.includes(key.name)) {
      pipeline.internal.push(...statusObj(key))
    }

    if (clientTypes.includes(key.name)) {
      pipeline.client.push(...statusObj(key))
    }
  }

  pipeline.sourcing.sort((a, b) => {
    return sourcingOrder.indexOf(a.id) - sourcingOrder.indexOf(b.id)
  })

  pipeline.client.forEach((item) => {
    externalTotalCount += item.count
  })

  const exteralPipelineTotalCount = {
    id: 'external',
    title: obj.ctx.$i18n.t('msp.v2.section.client'),
    count: `${obj.ctx.$compactNumber(externalTotalCount)}/${
      obj.maxSubmissions === -1 || obj.maxSubmissions === null
        ? '-'
        : obj.ctx.$compactNumber(obj.maxSubmissions)
    }`,
    link: ''
  }

  pipeline.client.unshift(exteralPipelineTotalCount)

  return pipeline
}
